.project-item{
   width : 100%;
   color : white;
   font-family: 'MTRL-R';

   &-content{
      position : relative;
      height : 100%;
      width  : 100%;

      &-hover{
         position : absolute;
         height : 100%;
         width  : 100%;
         z-index: 4;

         &-text{
            z-index: 4;
            position : absolute;
            font-size: 16px;
            width : 90%;
            bottom : 0px;
            left : 20px;
            display: flex;
            opacity: 0;
            text-transform : uppercase;

         }
         &-icon{
            z-index: 5;
            position : absolute;
            font-size: 20px;
            margin : 0;
            width : 85%;
            top : 10px;
            right : 20px;
            opacity: 0;
            text-align: right;
            text-transform : uppercase;
         }
         &-cover{
            z-index: 3;
            position : absolute;
            background : black;
            width: 100%;
            height: 100%;
            opacity: 0;
         }
         &:hover{
            .project-item-content-hover-text{
               animation : fadeinHoverText 0.4s forwards;
            }
            .project-item-content-hover-icon{
               animation : fadeinHoverText 0.4s forwards;
            }
            .project-item-content-hover-cover{
               animation : fadeinHoverImg 0.4s forwards;
            }
         }
         &:not(:hover){
            .project-item-content-hover-text{
               animation : fadeOutHoverText 0.4s forwards;
            }
            .project-item-content-hover-icon{
               animation : fadeOutHoverText 0.4s forwards;
            }
            .project-item-content-hover-cover{
               animation : fadeOutHoverImg 1s forwards;
            }
         }
      }
      &-img{
         z-index : 1;
         position : absolute;
         background-size : cover;
         background-position : center;
         height : 100%;
         width  : 100%;
      }
   }
   &:hover{
      filter: grayscale(100%);
   }
}

@keyframes fadeinHoverText {
   from {  opacity: 0; }
   to {  opacity : 1; }
}
@keyframes fadeinHoverImg {
   from {  opacity: 0; }
   to {  opacity : 0.8; }
}

@keyframes fadeOutHoverText {
   from{  opacity: 1; }
   to {  opacity : 0; }
}
@keyframes fadeOutHoverImg {
   from {  opacity: 0.8; }
   to {  opacity : 0; }
}



@media screen and (max-width: 700px) {
   .project-item{
      height : 100px;
   }
}

