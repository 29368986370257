.creations{
   display : block;
   background : white;
   color : black;

   &-texts{
      display : flex;
      justify-content: space-between;

      &-title{
         margin : 80px 50px;
      }
      &-text{
         margin : 108px 50px 50px 50px;
         font-size : 15px;
         max-width : 500px;
      }
   }

   &-filters{
      display: flex;
      justify-content: center;
      margin-top: 20px;
   }

   &-selected{
      position : relative;
      width    : 100%;
      height   : 100%;
      margin-top : 0;

      &-content{
         z-index : 98;
         display : flex;
         justify-content : center;
         width   : 100%;
         height  : 100%;
         
         &-img{
               z-index : 98;
               display : block;
               margin : 0;
               // margin-left : 50px;
               // width : 50%;
               height : 100%;

               width : 60%;

               &__picture{
                  width : 100%;
                  height : 500px;
                  background-size : cover;
                  background-position : center;
               }
         }

         &-data{
            display : flex;
            flex-direction: column;
            justify-content: space-between;
            z-index: 98;

            &-texts{
               z-index    : 98;
               margin     : 0 20px;
               margin-top : 30px;
               max-width  : 700px;
               font-size  : 15px;

               &-text{
                  margin-top : 5px;
               }
               
               &-sections{
                  font-family: "MTRL-R";
                  padding : 20px 0;
                  text-transform: uppercase;
               }
               &-badges{
                  display : flex;
                  flex-wrap: wrap;
                  width : 100%;
               }
            }
   
            &-imgs{
               margin-bottom : 32px;
               display : flex;
               width : 100%;

               // margin : 0;
               //    margin-top : 20px;
            }
         }
      }
      &-banner{
         position : absolute;
         z-index : 0;
         bottom : 0;
         width : 100%;
         height : 100px;
         background : black;
         // display: none;

      }
   }
}


@media screen and (max-width: 1130px) {
   .creations{
      &-selected{
         margin-top : 0;
         &-content{
            display : block;

            &-img{
                  margin: 0;
                  width : 100%;
            }
            &-data{
               font-size : 15px;

               &-imgs{
                  margin : 0;
                  margin-top : 20px;
               }
            }
         }
         &-banner{
            display: none;
         }
      }
   }  
}

@media screen and (max-width: 870px) {
   .creations{
      &-texts{
         display : block;
         
         &-text{
            max-width : 700px;
         }
      }
   }
}

@media screen and (max-width: 700px) {
   .creations{
      &-filters{
          flex-direction: column;
          margin : 20px;
      }
      &-selected{
         &-content{
            &-img{
               &__picture{
                  height : 300px;

               }
            }
         }
      }
  }
}