html{
  scroll-behavior: smooth;
  background : #000000;
  cursor: none !important;
  /* cursor: crosshair; */

}
body {
  margin: 0;
  font-family: 'MTRL-L';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* cursor: crosshair; */
  scroll-behavior: smooth;
}

ul{
  display: flex;
}


@font-face {
  font-family: 'EO-TI';
  src: local('PPEditorialOld-ThinItalic.woff2'), url(./_fonts/PPEditorialOld-ThinItalic.woff2) format('woff2');
}
@font-face {
  font-family: 'EO-UI';
  src: local('PPEditorialOld-UltralightItalic.woff2'), url(./_fonts/PPEditorialOld-UltralightItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'MTRL-L';
  src: local('PPNeueMontreal-Light.woff2'), url(./_fonts/PPNeueMontreal-Light.woff2) format('woff2');
}
@font-face {
  font-family: 'MTRL-R';
  src: local('PPNeueMontreal-Regular.woff2'), url(./_fonts/PPNeueMontreal-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'MTRL-M';
  src: local('PPNeueMontreal-Medium.woff2'), url(./_fonts/PPNeueMontreal-Medium.woff2) format('woff2');
}