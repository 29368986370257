.lang{
   display: flex;
   justify-content: center;
   align-items: center;
   border : 1px solid white;
   font-size: 20px;
   color : white;
   text-align: center;
   border-radius: 500px;
   padding : 8px;
   height : 100%;
   margin : 50px 50px 50px 0;
   // mix-blend-mode: difference;

   &:hover{
      animation : fadeInLang 0.3s forwards;
   }
   &:not(:hover){
      animation : fadeOutLang 0.3s forwards;
   }
}



@keyframes fadeInLang {
   from { 
      color : white;
   }
   to { 
      background : white;
      color : black;
   }
}
@keyframes fadeOutLang {
   from { 
      background : white;
      color : black;
   }
   to { 
      color : white;
   }
}

