.mainPage {
    position: relative;
    display   : flex;
    flex-direction: column;
    font-size : 25px;
    color : white;
    overflow : hidden;
    background : black;

    &-blank{
        position: absolute;
        width : 100%;
        height : 100%;
        z-index : 100;
        background-color: black;
        animation : fadeinBG 1s forwards;
        pointer-events: none;
    }

    &-content{
        display: flex;
        flex-direction: column;

        .logo{
            z-index  : 99;
            display  : flex;
            justify-content: center;
            align-items: center;
            position : fixed;
            background: rgba(240, 240, 240, 0.7);
            align-self: center;
            width : 120px;
            height : 45px;
            top : -2px;
            backdrop-filter: blur(3px);
            mix-blend-mode:difference;
            
            &-item{
                width:95px; 
                height:22px;
            }
        }
    
        .header{
            position: relative;
            height: 100vh;

            &-studio{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width  : 100%;
                height : 100%; 

                &-title{
                    width: 80%;
                    margin-top:-90px;
                    padding : 0 40px;
                } 
            }
            &-filter{
                z-index: 1;
                position: absolute;
                height: 100vh;
                width: 100%;
                background-position:top;
            }
            &-image{
                object-fit: cover;
                z-index: 0;
                width : 100%;
                height: 100vh;
                background-size: cover;
                // filter: grayscale(100%);

            }
            &-data{
                position: absolute;
                display: flex;
                justify-content: space-between;
                z-index: 2;
                bottom : 0;
                width: 100%;
                
                &-specialities{
                    flex-direction: column;
                    display : flex;
                    font-size: 14px;
                    font-family: 'MTRL-R';
                    color : white;
                    text-transform: uppercase;
                    margin : 50px 0 50px 50px;
                    mix-blend-mode: difference;
                    
                    &-item{
                        padding : 2px 0px;
                    }
                }
            }
        }
    
        .philosophy{
            display : flex;
            justify-content: space-between;
            background : white;
            color : black;

            &-title{
                margin : 80px 50px;
            }
            &-text{
                margin : 108px 50px;
                font-family: "MTRL-L";
                font-size : 15px;
                max-width : 500px;
            }
        }
    
        .knowledges{
            display : block;
            padding-bottom : 10px;
            &-texts{
                display : flex;
                justify-content: space-between;
    
                &-title{
                    margin : 80px 50px;
                }
                &-text{
                    margin : 108px 50px;
                    font-family: "MTRL-L";
                    font-size : 15px;
                    max-width : 500px;
                }
            }
            &-lists{
                margin-bottom : 20px;
            }
        }
        .contacts{
            display : block;
            
            &-texts{
                display : flex;
                justify-content: space-between;
    
                &-title{
                    margin : 80px 50px;
                }
                &-btns{
                    // background : red;
                    display: flex;
                    font-size : 15px;
                    align-items: flex-end;
                    flex-direction: column;
                    margin : 108px 50px 108px 0px;
                    max-width : 500px;
                }
            }
            &-credits{
                margin : 50px;
                margin-top : 0px;
    
                &-line{
                    font-size : 15px;
                    font-family: "MTRL-L";
                    text-transform: uppercase;
                }
            }
        }
    }
}


	//------------------------------------------------------------//
	//---------------------- ANIMATIONS -------------------------//
   //------------------------------------------------------------//

@keyframes fadeinBG {
    from { 
        opacity: 1;
    }
    to { 
        display : none;
        opacity : 0;
    }
}


	//------------------------------------------------------------//
	//---------------------- RESPONSIVE -------------------------//
   //------------------------------------------------------------//

@media screen and (max-width: 870px) {
    .mainPage{
        &-content{
            .philosophy{
                display : block;
                &-text{
                     max-width : 700px;
                }
            }
            .knowledges{
                &-texts{
                    display : block;
    
                    &-text{
                        max-width : 700px;
                    }
               }
            }
            .contacts{
                &-texts{
                    display : block;
    
                    &-btns{
                        display : flex;
                        justify-content: center;
                        align-items: center;
                        width : 100%;
                        max-width : 1000px;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 700px) {
    .mainPage{
        &-content{
            .knowledges{
                &-texts{
                    display : block;
                }
            }
            .contacts{
                &-texts{
                    display : block;
                }
            }
        }
    }
}