.badge{
   float : left;
   display : block;
   justify-content: center;
   align-items: center;
   padding : 6px 11px;
   font-size: 12px;
   font-family: "MTRL-R";
   margin-right : 10px;
   margin-bottom : 10px;
   text-transform: uppercase;
   color : black;
}
