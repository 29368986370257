.loaderPage{
    position: relative;
    display: flex;
    align-items : center;
    justify-content : center;
    font-size : 25px;
    height : 100vh;
    background-color: black;
    flex-direction: column;
    color : black;
    animation: fadeInLoader 1s forwards;

    &.loaderFadeOut{
        animation: fadeOutLoader 0.5s forwards;
    }
}
@keyframes fadeInLoader {
    0% {opacity: 0;}
    100% {opacity: 1;}
}