.marquee{
    overflow: hidden;
    text-transform : uppercase;
    background     : none;

    &-big{
        line-height : 0.8em;
        font-family : "MTRL-M";
        font-size   : 150px;

        &:hover{
            animation : fadeinHoverMarqueeBig 0.5s forwards;
        }
        &:not(:hover){
            animation : fadeOutHoverMarqueeBig 0.5s forwards;
        }
    }

    &-small{
        font-size : 20px;
        font-family: "MTRL-R";
        padding : 3px 0px;
        
        &.--black{
            background : black;
            color : white;
            border : 1px solid black;
        }
        &.--white{
            background : white;
            color : black;
            border : 1px solid white;
        }
    }
}



@keyframes fadeinHoverMarqueeBig {
    from {}
    to {  
        color : black;
        text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
    }
}
@keyframes fadeOutHoverMarqueeBig {
    from {  
        color : black;
        text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
    }
    to{}
}
 


@media screen and (max-width: 1130px) {
    .marquee{
        &-small{
            font-size : 20px;
        }
    }
}
@media screen and (max-width: 870px) {
    .marquee{
        &-big{
            font-family : "MTRL-R";
            font-size   : 80px;
        }
    }
}