// Cursor.scss
.cursor {
    display :none;
    background: radial-gradient(
        farthest-side,
        transparent calc(100% - 3px),
        white calc(100% - 2px) calc(100% - 1px),
        transparent 100%
    )
    fixed no-repeat;
    background-size: 20px 20px;
    z-index : 999;
    pointer-events: none;
    cursor: none !important;

    &::after {
        content: "";
        z-index : 999;
        mix-blend-mode: difference;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(
            farthest-side,
            white calc(100% - 1px),
            transparent 100%
        )
        fixed no-repeat;
        background-size: 20px 20px;
        background-position: inherit;
  }
}