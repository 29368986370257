.btn{
   display : flex;
   justify-content: center;
   align-items: center;
   border: 1px solid black;
   padding : 5px 15px;
   font-size: 18px;
   margin : 0 10px;
   font-family: "MTRL-R";
   border-radius: 100px;
   text-transform: uppercase;

   &:hover{
      animation : fadeInBtn 0.3s forwards;
   }
   &:not(:hover){
      animation : fadeOutBtn 0.3s forwards;
   }

   &.--selected{
      background : black;
      color : white;
      border: 1px solid black;
   }
}



@keyframes fadeInBtn {
   from { 
   }
   to { 
      background : black;
      color : white;
      border: 1px solid black;
   }
}
@keyframes fadeOutBtn {
   from { 
      background : black;
      color : white;
      border: 1px solid black;
   }
   to { 
   }
}


@media screen and (max-width: 700px) {
   .btn{
      margin : 5px 50px;
   }
}