.mini-btn{
   display : flex;
   justify-content: center;
   align-items: center;
   padding : 10px 0;
   font-size: 15px;
   font-family: "MTRL-R";
   text-transform: uppercase;
   border-radius: 100px;

   &-icon{
      margin-right : 10px;
      display : none;
   }

   &:hover{
      .mini-btn-icon{
         display : flex;
      }
   }
}