.title{
   display : flex;

   &-nbr{
       display         : flex;
       align-self      : flex-end;
       font-family     : "MTRL-L";
       line-height     : 1.5em;
       vertical-align  : sub;
       position        : relative;
   }
   &-name{
       font-family  : "EO-TI";
       font-size    : 90px;
       font-weight  : 100;
       padding      : 0 15px;
       margin       : 0;
       line-height  : 1em;
       font-variant-ligatures:discretionary-ligatures;
   }
}

@media screen and (max-width: 870px) {
   .title{
       display: flex;
       justify-content: center;
   }
}

@media screen and (max-width: 500px) {
   .title{
      display : flex;
      &-name{
          font-size : 50px;
          padding   : 0 10px;
      }
   }
}