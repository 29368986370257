.carousel-container{
   position: relative;
   width: 100%;
   z-index: 95;
   // margin-top: 50px;
   // padding : 100px;
   margin : 50px 0;
}
.carousel-item{
   width : 500px;
   height : 200px;
}

.carousel-button{
   position : absolute;
   z-index  : 98;
   background : white;
   color : white;
   width : 30px;
   height : 30px;
   padding : 5px;
   z-index: 98;
   // mix-blend-mode: difference;

   &.--left{
      left : 0;
   }
   &.--right{
      right : 0;
   }
}

// @media screen and (max-width: 700px) {
//    .carousel-item{
//       height : 100px;
//    }
// }